import * as React from "react"

import Layout from "../prismic/layout"
import { Col, Container, Row } from "../components/Grid/Grid"
import PrismicFooter from "../prismic/layout/PrismicFooter"
import { PrismicNavigation } from "../prismic/layout/PrismicNavigation"
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"

const NotFoundPage = () => (
  <Layout>
    <PrismicNavigation lang={"en-ca"}/>
    <Container style={{ paddingTop: "208px", paddingBottom: "7rem" }}>
      <Row>
        <Col col={12}>
          <h1>Page Not Found</h1>
          <p>Sorry we couldn't find the page you were looking for.</p>
        </Col>
      </Row>
    </Container>
    <PrismicFooter lang={"en-ca"}/>
  </Layout>
)

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withPrismicUnpublishedPreview(NotFoundPage)
